import React, { useContext } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import memoize from 'lodash/memoize';
import { UrlObject } from 'url';
import { Trans } from '@lingui/react';
import { Flex, Container } from '@components/layout/Grid';
import {
  BestPriceLabel,
  SubscriptionsWrapper,
  SubscriptionTitle,
} from '@components/templates/showcase/components';
import { UserContext } from '@lib/contexts/UserProvider';
import LanguageContext from '@lib/contexts/languageContext';
import {
  PricingAvailableSubscriptionPlansQuery,
  usePricingAvailableSubscriptionPlansQuery,
} from '@gql/generated';
import PricingTrialCard from './PricingTrialCard';
import PricingSubscriptionCard from './PricingSubscriptionCard';
import { Bullets } from './BulletList';
import { Title } from '@ecosystems/landing_pages/hero/components';

const BulletsItems = styled(Bullets)`
  justify-content: center;
`;

const findHighlightedSubscription = memoize(
  (
    subscriptions: PricingAvailableSubscriptionPlansQuery['availableAllCodes']['subscriptions'],
    defaultHighlight
  ) => {
    const promotion = subscriptions?.find(
      subscription => subscription.promotionName
    );

    // we try to find promotion if not found we return default
    return promotion?.subscriptionPlan || defaultHighlight;
  }
);

const PricingWrapper = styled.div<{ highlightColor?: string }>`
  padding: 40px 20px;
  background-color: #f0f5fb;

  ${SubscriptionTitle} {
    font-size: 24px;
    color: #243240;
  }

  .SubscriptionBoxCard--HighlightedCard {
    ${BestPriceLabel} {
      background-color: ${({ highlightColor, theme }) =>
        highlightColor || theme.colors.primary['blue-2']};
    }

    button {
      background-color: ${({ highlightColor, theme }) =>
        highlightColor || theme.colors.primary['blue-2']};
    }
  }

  @media (min-width: ${props => props.theme.viewports.tablet}px) {
    ${SubscriptionTitle} {
      font-size: 34px;
    }
  }
`;

export const DEFAULT_PRICING_BULLETS = [
  <Trans key={1} id="pricing_section.bullets.option1" />,
  <Trans key={2} id="pricing_section.bullets.option2" />,
  <Trans key={3} id="pricing_section.bullets.option3" />,
  <Trans key={4} id="pricing_section.bullets.option4" />,
];
type Props = {
  id?: string;
  className?: string;
  bullets?: Array<React.ReactElement>;
  bestValue?: string;
  promotionCode?: string;
  campaign?: string;
  highlightColor?: string;
  showTrialCard?: boolean;
  returnToPage?: string;
  handlePlanSelect?(paymentType: string, route: UrlObject): void;
};

const PricingSection = (props: Props) => {
  const {
    id = null,
    className = '',
    bullets = DEFAULT_PRICING_BULLETS,
    bestValue = 'yearly',
    promotionCode = null,
    campaign = null,
    highlightColor = null,
    returnToPage = null,
    showTrialCard = true,
    handlePlanSelect = null,
  } = props;
  const router = useRouter();
  const lang = useContext(LanguageContext);
  const [currentUser, { loading }] = useContext(UserContext);
  const {
    data,
    loading: subscriptionLoading,
  } = usePricingAvailableSubscriptionPlansQuery({
    variables: { code: promotionCode },
  });

  // the tree diffing ALGO gets confused when we render a box & remove it on client
  // so we delay rendering the cards until user & subscription is loaded or its null
  if (
    !data ||
    subscriptionLoading ||
    (loading && !currentUser?.id) ||
    currentUser === undefined
  ) {
    return null;
  }

  const toHighlight = findHighlightedSubscription(
    data?.availableAllCodes?.subscriptions,
    bestValue
  );

  const isUserLoggedIn = !!currentUser?.id;
  const onPlanSelect = (paymentType = null) => {
    const params = {
      paymentType,
      //
      ...(returnToPage ? { to: returnToPage } : {}),
      // campaign is used for tracking purposes
      ...(campaign ? { campaign } : {}),
      // if code is not valid or not working any more we dont want to send it anyway
      ...(data?.availableAllCodes?.isCodeValid ? { code: promotionCode } : {}),
    };

    const pathname = `/${lang}/subscription/select`;

    if (!isUserLoggedIn) {
      router.push({
        pathname: `/${lang}/register/account`,
        query: params,
      });
    } else if (typeof handlePlanSelect === 'function') {
      handlePlanSelect(paymentType, {
        pathname,
        query: params,
      });
    } else {
      router.push({
        pathname,
        query: params,
      });
    }
  };

  const trialOffer =
    data?.availableAllCodes?.trials?.[0] ??
    data?.availableAllCodes?.trialReferralCodes?.[0];

  return (
    <PricingWrapper
      className={className}
      id={id}
      highlightColor={highlightColor}
    >
      <Container flexDirection="column">
        <Title as="h2" id="subscription_section" className="text-center">
          <Trans id="pricing_section.title" />
        </Title>
        <Flex alignItems="center" justifyContent="center">
          <BulletsItems bullets={bullets} />
        </Flex>
        <SubscriptionsWrapper
          alignItems="center"
          justifyContent="center"
          pt={40}
          mx="-10px"
        >
          {!isUserLoggedIn && showTrialCard ? (
            <PricingTrialCard
              trialDays={trialOffer?.days ?? 14}
              onPlanSelect={onPlanSelect}
            />
          ) : null}
          {/*  */}
          {data?.availableAllCodes?.subscriptions?.map(subscription => {
            if (subscription.promotionKind !== 'extra_global') {
              return (
                <PricingSubscriptionCard
                  key={subscription.subscriptionPlan}
                  subscription={subscription}
                  toHighlight={toHighlight}
                  onPlanSelect={onPlanSelect}
                />
              );
            }

            return null;
          })}
        </SubscriptionsWrapper>
      </Container>
    </PricingWrapper>
  );
};

export default PricingSection;
