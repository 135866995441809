import { Trans } from '@lingui/react';
import Button from '@components/buttons';
import {
  SubscriptionBox,
  SubscriptionBoxBilled,
  SubscriptionBoxPrice,
  SubscriptionBoxTitle,
} from '@components/templates/showcase/components';
import { cn } from '@lib/tw';

const PricingTrialCard = ({ className = '', trialDays = 14, onPlanSelect }) => {
  return (
    <SubscriptionBox className={cn('SubscriptionBox--Card md:px-4', className)}>
      <SubscriptionBoxTitle>
        <Trans
          id="pricing_section.plan.trial.title"
          values={{ days: trialDays }}
        />
      </SubscriptionBoxTitle>
      <SubscriptionBoxPrice>
        <Trans id="pricing_section.plan.trial.price" />
      </SubscriptionBoxPrice>
      <SubscriptionBoxBilled>
        <Trans id="pricing_section.plan.trial.body" />
      </SubscriptionBoxBilled>
      <Button
        className="Card--ApplyButton"
        appearance="dark-blue"
        onClick={() => {
          onPlanSelect();
        }}
      >
        <Trans
          id="pricing_section.plan.trial.button_text"
          values={{ days: trialDays }}
        />
      </Button>
    </SubscriptionBox>
  );
};

export default PricingTrialCard;
